<template>
  <div>
    <v-dialog v-model="internalShowModalValidate" persistent width='500'>
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-title class="headline mt-3" :style="{ color: '#429488'}">{{ title }}</v-card-title>
        <v-card-text class="font-weight-bold text-center mt-4" v-html="message"/>
        <v-card-actions class="justify-center py-7">
          <v-btn
            color="#FA7048"
            class="white--text text-center"
            @click="handleButtonClick"
          >{{ buttonText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'modal_validate',
  data() {
    return {
      internalShowModalValidate: this.value,
    };
  },
  props: {
    value: Boolean,
    type: String,
    postulantData: Object,
  },
  computed: {
    title() {
      let greeting = this.postulantData?.name ? `¡Hola ${this.postulantData?.name}!` : '¡Hola!';

      switch (this.type) {
        case 'blackList':
        case 'pendingTest':
        case 'hasOpenProcess':
        case 'completedTest':
        case 'isWorkingKonecta':
          return greeting;
        case 'processNotFound':
          return '¡Ups!';
        default:
          return 'Registro';
      }
    },
    message() {
      switch (this.type) {
        case 'blackList':
          return 'Observamos que has postulado anteriormente. Te contactaremos cuando tengamos una nueva oportunidad que se ajuste mejor a tu perfil. ¡Gracias por volver a considerar ser parte de nuestra familia!';
        case 'pendingTest':
          return `Ya te encuentras participando en proceso de selección del puesto: <strong>${this.postulantData?.vacancy}</strong>. Para poder continuar, debes realizar las pruebas que tienes pendiente de desarrollar.`;
        case 'completedTest':
          return '¡Gracias por completar tus pruebas! Estamos revisando los resultados y pronto nos contactaremos contigo. Mientras tanto, por favor, mantente al tanto de tus mensajes.';
        case 'hasOpenProcess':
          return `Ya te encuentras participando en proceso de selección para el puesto: <strong>${this.postulantData?.vacancy}</strong>. Por favor completa y finaliza este proceso primero antes de buscar nuevas oportunidades.`;
        case 'isWorkingKonecta':
          return 'Parece que ya eres parte de nuestro equipo. Por favor conversa con tu líder para explorar oportunidades de cambio. ¡Gracias por querer crecer con nosotros!';
        case 'processNotFound':
          return 'El proceso de selección no fue encontrado. Por favor, verifica que el link sea correcto o contacta a tu reclutador.';
        default:
          return 'El postulante puede proceder a registrar todos sus datos.';
      }
    },
    buttonText() {
      switch (this.type) {
        case 'pendingTest':
          return 'Terminar mis pruebas';
        case 'completedTest':
          return 'Entiendo';
        default:
          return 'Cerrar';
      }
    },
  },
  watch: {
    value(newVal) {
      this.internalShowModalValidate = newVal;
    },
    internalShowModalValidate(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    handleButtonClick() {
      if (this.type === 'pendingTest') {
        const testLink = sessionStorage.getItem('link-evaluar');
        window.open(testLink, '_blank');
      }
      this.internalShowModalValidate = false;
    },
  }
};
</script>
